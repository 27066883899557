<!--
 * @Author: lzh
 * @Date: 2022-07-06 14:34:25
 * @LastEditors: lbh
 * @LastEditTime: 2024-10-26 10:25:59
 * @Description: file content
-->
<template>
  <div
    class="map-box px-map-box"
    @click="clickItem('title')"
    :class="`edit_${Xindex}_title`"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    >
    </h1>
    <div
      class="map"
      id="map"
    ></div>
    <div
      title="點擊切換地圖類型"
      class="changeMap"
      @click="changeMapFun"
    >
      <img src="https://ricepon.oss-cn-hongkong.aliyuncs.com//home/mapChange.png" />
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'self-map',
  data () {
    return {
      isEdit: '',
      header: { title: '地圖詳情', back: true },
      self_this: null,
      map: null,
      value: '',
      service: null,
      markers: [],
      mapType: 'ROADMAP', // ROADMAP  HYBRID
      nowL: '',
    };
  },
  props: {
    id: {
      type: String,
      default: () => {
        return '';
      },
    },
    configs: {
      default () {
        return {
          title: '',
          img: '',
          lat: '',
          lng: '',
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
    lang: {
      default () {
        return '';
      }
    }
  },
  watch: {
    lang () {
      this.initLang();
    }
  },
  created () {
    // * 記錄點擊流
   this.$point.log({
      logType: 426,
      contentType: '',
      contentId: "official/do/map",
    });
    this.initLang();
    this.isEdit = this.$store.getters.getIsEdit;
    this.initMap();
  },
  methods: {
    initLang () {
      let now_language = this.$storage.get('now_language');
      if (now_language === 'HK') now_language = '';
      this.nowL = now_language;
    },
    //初始化地图
    initMap () {
      const loader = new Loader({
        apiKey: 'AIzaSyCujUc1iFYa8GBWthpm3IOJp6_rxcBjI-I', //填写google控制台中的apikey
        libraries: ['places', ''],
      });
      loader.load().then(() => {
        this.map = new google.maps.Map(document.getElementById('map'), {
          center: {
            lat: Number(this.configs.lat),
            lng: Number(this.configs.lng),
          },
          mapTypeId: google.maps.MapTypeId[this.mapType],

          zoom: 20,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          language: 'zh-hk',
        });
        this.service = new google.maps.places.PlacesService(this.map);
        let position;
        if (
          this.configs.lat == '' ||
          this.configs.lng == '' ||
          this.configs.lat == null ||
          this.configs.lng == null
        ) {
          position = {
            lat: 22.3392,
            lng: 114.1511,
          };
        } else {
          position = {
            lat: Number(this.configs.lat),
            lng: Number(this.configs.lng),
          };
        }
        const marker = new google.maps.Marker({
          position: { lat: position.lat, lng: position.lng },
          draggable: false,
          map: this.map,
          title: '',
        });
        this.markers.push(marker);
      });
    },
    changeMapFun () {
      let type = this.mapType;
      if (type == 'ROADMAP') this.mapType = 'HYBRID';
      else if (type == 'HYBRID') this.mapType = 'ROADMAP';
      this.initMap();
    },
    clickItem (e) {
      if (this.isEdit) {
        this.$emit('clickItem', e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.map-box {
  padding: 70px 320px;
  position: relative;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
  }
  .map {
    width: 100%;
    height: 600px;
    margin-top: 50px;
  }
  .changeMap {
    position: absolute;
    bottom: 70px;
    left: 320px;
    z-index: 1;
    cursor: pointer;
  }
}
@media screen and (max-width: 1000px) {
  .px-map-box {
    padding: 15px 0;
    .title {
      font-size: 15px;
      font-weight: 500;
      color: #000000;
      padding: 15px 0;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #ccc;
      }
    }
    .map {
      height: calc(100vw / 2.14);
    }
    .changeMap {
      left: 5px;
      bottom: 10px;
      img {
        width: 30px;
      }
    }
  }
}
</style>
